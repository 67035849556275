import {emailPattern} from "./regex";

export const contactFormInputs = [
    {
        type: 'text',
        name: 'cfFirstName',
        label: (lang) => lang.common.firstName,
        rules: {
            required: true
        }
    },
    {
        type: 'text',
        name: 'cfLastName',
        label: (lang) => lang.common.lastName,
        rules: {
            required: true
        }
    },
    {
        type: 'email',
        name: 'cfEmail',
        label: (lang) => lang.common.email,
        rules: {
            required: true,
            pattern: emailPattern
        }
    },
];