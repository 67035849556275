import Container from "./Container";
import { useCallback, useEffect, useRef, useState } from "react";
import Fadeout from "./Fadeout";

let heroTimeout;

export default function HeroVideo({
  mobileVideo,
  tabletVideo,
  desktopVideo,
  children,
  size = "big",
  align = "center",
  className = "",
}) {
  const [activeVideo, setActiveVideo] = useState(null);

  const videoRef = useRef();
  const currentVideo = useRef();

  const handleResize = useCallback(() => {
    clearTimeout(heroTimeout);

    let newVideo;

    if (window.innerWidth > 1024) {
      newVideo = desktopVideo;
    } else if (window.innerWidth > 500) {
      newVideo = tabletVideo;
    } else {
      newVideo = mobileVideo;
    }

    // Timeout to not get errors because of .load()
    heroTimeout = setTimeout(() => {
      if (currentVideo && currentVideo.current !== newVideo) {
        setActiveVideo(newVideo);
        currentVideo.current = newVideo;

        if (videoRef.current) {
          setTimeout(() => {
            videoRef.current?.load();
          }, 200);
        }
      }
    }, 200);
  }, [desktopVideo, mobileVideo, tabletVideo]);

  useEffect(() => {
    window?.addEventListener("resize", handleResize);

    setTimeout(() => {
      handleResize();
    }, 500);

    return () => window?.removeEventListener("resize", handleResize);
  }, [handleResize]);

  useEffect(() => {
    if (currentVideo.current) {
      videoRef.current?.load();
    }
  }, []);

  return (
    <section
      className={`relative ${
        size === "big"
          ? "h-hero-mobile md:h-hero-big"
          : "h-hero-mobile md:h-hero-medium"
      } flex items-center bg-black text-white hero ${className}`}
    >
      <div className="absolute h-full w-full top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 max-w-hero-video video-overlay">
        <Fadeout
          direction="top"
          color="black"
          className="w-full h-2/3 md:hidden"
        />
        <Fadeout
          direction="right"
          color="black"
          className="w-[400px] hidden xl:block"
        />
        <Fadeout
          direction="left"
          color="black"
          className="w-[400px] hidden xl:block"
        />

        <video
          autoPlay
          loop
          muted
          playsInline
          preload="metadata"
          className="absolute w-full h-full object-cover"
          ref={videoRef}
        >
          <source src={activeVideo} type="video/mp4" />
        </video>
      </div>
      <Container
        className={`relative h-full flex ${
          align === "center" ? "items-center" : "items-end"
        } z-10`}
      >
        {children}
      </Container>
    </section>
  );
}
