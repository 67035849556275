import {useEffect} from "react";

export default function LightboxBackground({active, className, onClick, children}) {
    let classes = [
        'fixed top-0 left-0 w-screen h-screen p-6 bg-black bg-opacity-60 z-40 flex overflow-auto justify-center transition-opacity duration-300',
        active ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none',
        className ? className : ''
    ].join(' ');

    useEffect(() => {
        active ? document.body.classList.add('overflow-hidden') : document.body.classList.remove('overflow-hidden');
    }, [active]);

    return (
        <div className={classes} onClick={onClick}>
            {children}
        </div>
    )
}
