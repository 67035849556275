import SvgLogoLocation from "../svg/SvgLogoLocation";

export default function MapLocationTabButton({location, district, active = false, className = '', ...rest}) {
    return (
        <button
            className={`py-4 px-4 md:px-8 inline-flex items-center min-w-max gap-3 md:gap-4 rounded-tl-[5px] rounded-tr-[5px] transition-colors duration-300 ${active ? 'bg-black text-white' : 'bg-white text-black/30 mouse-hover:text-black'} ${className}`}
            {...rest}
        >
            <div className="aspect-square h-[30px]">
                <SvgLogoLocation id={`location-tab-logo-${location}`} location={location} className="h-full"/>
            </div>
            <span
                className={`${active ? '' : 'hidden sm:inline'} whitespace-nowrap leading-none md:text-lg md:leading-none font-semibold`}>
                {district}
            </span>
        </button>
    )
}
