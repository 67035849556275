const translateDay = (day, lang) => {
    return lang.common[day.toLowerCase()] || day.toLowerCase();
};

const formatTime = (time) => {
    const parts = time.split(':');
    return `${parts[0]}:${parts[1]}`;
};

export const formatOpeningHours = (hours = null, lang) => {
    if (!hours) return [];

    return hours.map(({dayOfWeekFrom, dayOfWeekTo, timeFrom, timeTo}) => {
        const data = {
            hours: `${formatTime(timeFrom)} - ${formatTime(timeTo)}`
        }

        if (dayOfWeekFrom === dayOfWeekTo) {
            data.days = translateDay(dayOfWeekFrom, lang);
        } else {
            data.days = `${translateDay(dayOfWeekFrom, lang)} - ${translateDay(dayOfWeekTo, lang)}`;
        }

        return data;
    });
};

export const getTranslatedName = (str, lang) => {
    if (!str) return '';
    return lang.common[str.toLowerCase()] || str.toLowerCase();
};

export const getOpeningHours = (studio, lang) => {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();

    // if date is after 1.1.2024, return normal opening hours
    if (year > 2023 && month >= 0 && day > 1) {
        return {
            condensed: false,
            items: formatOpeningHours(studio?.openingHours, lang)
        };
    }

    const langOnly = lang.common.only.toLowerCase();

    return {
        condensed: true,
        items: [
            {days: '23.12.', hours: '8:00 - 20:00', additional: lang.common.allLocations.toLowerCase()},
            {days: '24.12.', hours: '8:00 - 15:00', additional: `${langOnly} Karlín`},
            {days: '25.12.', hours: lang.common.closed},
            {days: '26.12.', hours: '8:00 - 20:00', additional: `${langOnly} Karlín`},
            {days: '31.12.', hours: '8:00 - 15:00', additional: `${langOnly} Karlín`},
            {days: '1.1.', hours: '10:00 - 20:00', additional: `${langOnly} Karlín`},
        ]
    };
}