import HeroVideo from '../HeroVideo';
import Title from '../Title';
import Typo from '../Typo';
import SvgLogoBig from '../../svg/SvgLogoBig';
import {useRouter} from 'next/router';
import localized from '../../locale/locales';

export default function SectionHomepageHeader() {
    const {locale} = useRouter();
    const {title, body} = localized[locale].homepage.header;

    return (
        <HeroVideo
            mobileVideo='/videos/hero/homepage_mobile.mp4'
            tabletVideo='/videos/hero/homepage_tablet.mp4'
            desktopVideo='/videos/hero/homepage_desktop.mp4'
            poster='intro'
            size='big'
            align='bottom'
            className='pt-20 pb-32'
        >
            <div className='max-w-md'>
                <header className='mb-4'>
                    <Title
                        Elem='h1'
                        className='flex flex-col sm:flex-row items-start sm:items-center gap-1 sm:gap-2'
                    >
                        {title} <span className='text-0'>Next.Move</span>
                        <SvgLogoBig
                            width={207}
                            height={23}
                            className='max-w-full'
                            id="homepage-header"
                        />
                    </Title>
                </header>
                <Typo>
                    <span className='md:hidden'>{body.mobile}</span>
                    <span className='hidden md:inline'>{body.desktop}</span>
                </Typo>
            </div>
        </HeroVideo>
    );
}
