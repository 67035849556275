export default function SvgPlay(props) {
    return (
        <svg viewBox="0 0 25 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M4.84582 36L2.53304 33.3306L19.7137 18.2039L3.01028 3.52214H0V0H4.33186L25 18.2039L4.84582 36Z"
                  fill="currentColor"/>
            <path opacity="0.6" d="M4 3H0V28H4V3Z" fill="url(#svg_play)"/>
            <path
                d="M3.8777 27.284C3.46685 26.2612 2.31649 25.7293 1.28938 26.1384C0.262272 26.5476 -0.271825 27.6932 0.139019 28.716C0.549863 29.7388 1.70023 30.2707 2.72734 29.8616C3.75445 29.4933 4.24746 28.3069 3.8777 27.284Z"
                fill="currentColor"/>
            <defs>
                <linearGradient id="svg_play" x1="10.3753" y1="8.41705" x2="-3.63962" y2="24.9158"
                                gradientUnits="userSpaceOnUse">
                    <stop stopOpacity="0"/>
                    <stop offset="1"/>
                </linearGradient>
            </defs>
        </svg>
    )
}