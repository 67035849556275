import {useRouter} from "next/router";
import localized from "../../locale/locales";
import Container from "../Container";
import {useCallback, useState} from "react";
import Link from "next/link";
import SvgTick from "../../svg/SvgTick";
import {useForm} from "react-hook-form";
import {inputStyles} from "../../helpers/styles";
import Button from "../Button";
import {sendLeadToGtm} from "../../helpers/gtm";
import {contactFormInputs} from "../../helpers/forms";
import {getCookie, removeCookie} from "../../helpers/cookies";
import {getLocationStudioId} from "../../helpers/locations";

export default function SectionContactForm({theme = 'light', className = '', ...rest}) {
    const {locale} = useRouter();
    const lang = localized[locale];

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [result, setResult] = useState('none');
    const {register, handleSubmit, formState: {errors}, reset} = useForm();

    const themeClasses = theme === 'dark' ? 'bg-black text-white' : 'bg-white text-black';

    const onSubmit = useCallback(async (data) => {
        if (isSubmitting) return false;

        setIsSubmitting(true);

        const {
            cfFirstName: firstname,
            cfLastName: lastname,
            cfEmail: email,
        } = data;

        let notes = 'Contact Form';

        let hasUtmData = false;
        const utmData = getCookie('utmData');

        if (utmData) {
            notes += ' - ' + utmData;
            hasUtmData = true;
        }

        const body = {
            studioId: getLocationStudioId('holesovice'),
            firstname,
            lastname,
            email,
            notes
        };

        await fetch('/api/store-lead', {
            method: 'POST',
            body: JSON.stringify(body)
        }).then((res) => {
            if (res.status > 200) {
                setResult('error');
            } else {
                sendLeadToGtm();
                setResult('success');

                if (hasUtmData) removeCookie('utmData');

                setTimeout(() => {
                    reset();
                    setResult('none');
                }, 5000);
            }

            setIsSubmitting(false);

            return res.json();
        }).catch((err) => {
            console.error(err);
            setResult('error');
            setIsSubmitting(false);
        });
    }, [isSubmitting, reset]);

    return (
        <div className={`pb-24 ${themeClasses} ${className}`} id="newsletter">
            <Container className="relative flex flex-col items-center py-8">
                <div
                    className={`absolute top-0 left-0 w-full h-full z-10 flex bg-white/40 backdrop-blur transition-all duration-500 ${result !== 'none' ? '' : 'translate-y-1/2 opacity-0 pointer-events-none'}`}>
                    <div className="m-auto p-4 flex flex-col items-center gap-4">
                        <h3 className="text-3xl">
                            {lang.contactForm.responses[result]?.title}
                        </h3>
                        <p className="text-center max-w-[32ch]">
                            {lang.contactForm.responses[result]?.body}
                        </p>
                    </div>
                </div>
                <header className="mb-8 text-center">
                    <h2 className="text-3.5xl mb-4">
                        {lang.contactForm.title}
                    </h2>
                    <p>
                        {lang.contactForm.subtitle}
                    </p>
                </header>
                <div className="relative w-full flex flex-col items-center">
                    <form action=""
                          onSubmit={handleSubmit(onSubmit)}
                          className="w-full max-w-[800px] flex flex-wrap text-black"
                    >
                        {contactFormInputs.map((item, key) => {
                            const {type, name, rules, label} = item;

                            return (
                                <div
                                    className={`basis-full py-4 md:p-4 ${name === 'cfEmail' ? 'md:flex-1' : 'md:basis-1/2'}`}
                                    key={`Input: ${key}`}>
                                    <input {...register(name, rules)}
                                           type={type}
                                           className={inputStyles(errors[name], 'small')}
                                           placeholder={label(lang)}
                                    />
                                </div>
                            )
                        })}

                        <div className="basis-full md:basis-0 py-4 md:p-4 text-center md:text-right">
                            <Button
                                className="justify-center"
                                label={isSubmitting ? lang.common.submitting : lang.common.submit}
                            />
                        </div>

                        <div className="py-4 md:p-4 flex gap-5 items-center mx-auto">
                            <div>
                                <label
                                    className={`checkbox border-2 transition-colors ${errors.cfAgree ? 'border-red-400' : 'border-gray-300'}`}
                                    htmlFor="cfAgree">
                                    <input type="checkbox" id="cfAgree" {...register('cfAgree', {required: true})}/>
                                    <div className="inner">
                                        <SvgTick width={13} className="text-white"/>
                                    </div>
                                </label>
                            </div>
                            <label htmlFor="cfAgree"
                                   className="text-sm text-gray-300 font-medium block leading-relaxed">
                                {lang.common.agreeWith} <Link href="/zzou"
                                                              className="underline mouse-hover:no-underline"
                                                              target="_blank"
                            >{lang.docs.personalDataMarketing}</Link>
                            </label>
                        </div>
                    </form>
                </div>
            </Container>
        </div>
    )
}