import Lightbox from "../Lightbox";
import Video from "../Video";

export default function PopupVideo({active, activeVideo, onClose}) {
    return (
        <Lightbox active={active} onClose={onClose}>
            <Video url={activeVideo} active={active}/>
        </Lightbox>
    )
}
