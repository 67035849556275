import Banner from "../Banner";
import Container from "../Container";
import ConceptsCarousel from "../ConceptsCarousel";

export default function SectionConceptsCarousel({title, subtitle, className = ''}) {
    return (
        <section className={`text-center pt-16 pb-16 md:pb-32 flex flex-col gap-16 ${className}`}>
            <Container>
                <header>
                    <Banner title={title}
                            body={subtitle}
                            className="text-center"
                    />
                </header>
            </Container>

            <Container className="text-left" width="wide">
                <ConceptsCarousel/>
            </Container>
        </section>
    )
}
