import Lightbox from "../Lightbox";
import SvgLogoBig from "../../svg/SvgLogoBig";

export default function PopupOpeningHours({openingHours, active, onClose}) {
    const {condensed = false, items} = openingHours;

    return (
        <Lightbox active={active} onClose={onClose} size="small">
            <header className="mb-6 flex flex-col gap-3 items-start">
                <SvgLogoBig
                    height={18}
                    id="section-hours"
                />
            </header>

            <div className="flex flex-col font-semibold">
                {items.map((item, key) => {
                    const {days, hours, additional} = item;

                    return (
                        <div key={`Hours: ${key}`}
                             className={`flex items-start justify-between leading-none md:text-lg md:leading-none border-b last-of-type:border-0 ${condensed ? 'py-4' : 'py-6'}`}
                        >
                            <p>{days}</p>
                            <div className="text-right">
                                <p className="tabular-nums">{hours}</p>
                                {additional && (
                                    <p className="text-sm text-gray-400">{additional}</p>
                                )}
                            </div>
                        </div>
                    )
                })}
            </div>
        </Lightbox>
    )
}
