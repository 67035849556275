import Link from "next/link";

export default function PanelLink({className, onClick, href, children, ...rest}) {
    const classes = `flex-1 -mt-px sm:mt-0 w-full flex items-center justify-center gap-4 px-4 py-8 md:py-10 text-sm bg-white mouse-hover:bg-black text-black mouse-hover:text-white transition-colors duration-300 ${className ? className : ''}`;

    if (onClick) {
        return (
            <button className={classes}
                    onClick={onClick}
                    {...rest}
            >
                {children}
            </button>
        )
    }

    return (
        <Link href={href}
              className={classes}
              {...rest}
        >
            {children}
        </Link>
    )
}
