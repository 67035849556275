export default function SvgArrow({id = 'svg-arrow', ...rest}) {
    return (
        <svg viewBox="0 0 21 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path
                d="M19.9014 14.7001C20.175 14.079 19.9014 13.3544 19.3044 13.0697C18.7074 12.785 18.0109 13.0697 17.7372 13.6908C17.4636 14.3119 17.7372 15.0366 18.3342 15.3212C18.9313 15.6059 19.6278 15.3212 19.9014 14.7001Z"
                fill="currentColor"/>
            <path
                d="M11.792 23.2143L11.792 23.1884L11.792 0L9.42881 -1.03298e-07L9.42881 20.7298L1.66761 12.6812L0.000946368 14.4151L10.1253 24.9482L11.792 23.2143Z"
                fill="currentColor"/>
            <path opacity="0.6" d="M18.0355 13.2764L19.7021 15.0103L10.125 25L8.45837 23.2401L18.0355 13.2764Z"
                  fill={`url(#paint0_linear_963_547_arrow_${id})`}/>
            <defs>
                <linearGradient id={`paint0_linear_963_547_arrow_${id}`} x1="19.7139" y1="19.1294" x2="8.44794"
                                y2="19.1294" gradientUnits="userSpaceOnUse">
                    <stop offset="0.0158937" stopColor="currentColor"/>
                    <stop offset="1" stopColor="currentColor" stopOpacity="0"/>
                </linearGradient>
            </defs>
        </svg>
    )
}