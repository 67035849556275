import Typo from "./Typo";
import SvgArrow from "../svg/SvgArrow";
import { useCallback, useState } from "react";

export default function RateCol({
  name,
  description,
  subDescription = [],
  price,
  className,
}) {
  const [active, setActive] = useState(false);
  const isToggleable = subDescription.length > 3;

  const handleClick = useCallback(() => {
    if (isToggleable) {
      setActive(!active);
    }
  }, [active, isToggleable]);

  return (
    <article
      className={`flex flex-col flex-1 pt-6 pb-10 px-8 bg-white text-black rounded-md shadow-xl ${className}`}
    >
      <div
        onClick={handleClick}
        className={`${isToggleable ? "cursor-pointer accordion" : ""} ${(isToggleable && active) || !isToggleable ? "accordion-open" : ""}  border-b`}
      >
        <header className="pb-4 flex justify-between">
          <div>
            <h3 className="text-lg leading-normal mb-1">{name}</h3>
            <p className="text-sm text-gray-400 max-w-lg">{description}</p>
          </div>
          {isToggleable && (
            <div>
              <SvgArrow
                width={21}
                className={`transform transition-transform duration-500  ${active ? "rotate-180 " : ""}`}
                id="plan-col"
              />
            </div>
          )}
        </header>

        <div className="pb-6 px-4">
          <ul className="flex flex-col gap-1 list-outside">
            {subDescription?.map((item, key) => {
              return (
                <Typo Elem="li" key={`PlanCol: ${key}`}>
                  {item}
                </Typo>
              );
            })}
          </ul>
        </div>
      </div>

      <div className="block sm:flex justify-between items-center lg:block pt-8">
        <div className="flex flex-col text-center">
          {/*{terms[0]?.price && (*/}
          {/*<p className="text-5xl">{formatNumber(terms[0].price)}</p>*/}
          {/*)}*/}
          {price && <p className="text-5xl">{price}</p>}
          {/*{suffix && (*/}
          {/*    <small className="mt-2 text-gray-300 leading-none">{suffix}</small>*/}
          {/*)}*/}
        </div>
      </div>
    </article>
  );
}
