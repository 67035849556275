import {useCallback, useEffect, useRef} from "react";
import Container from "../Container";
import Title from "../Title";
import Typo from "../Typo";
import {useRouter} from "next/router";
import localized from "../../locale/locales";
import Button from "../Button";
import Map from "../Map";
import {getLocationData} from "../../helpers/locations";
import LocationBgComponent from "../LocationBgComponent";

const calcToMove = (elem, intensity, pageYOffset) => {
    const bodyRect = document.body.getBoundingClientRect();
    const elemRect = elem.parentElement.getBoundingClientRect();
    const elemOffset = elemRect.top - bodyRect.top;
    return (pageYOffset - elemOffset) * intensity;
}

export default function SectionMap({location}) {
    const {locale} = useRouter();
    const locationsLang = localized[locale].locations;
    const refMap = useRef();
    const refLogo = useRef();

    const handleScroll = useCallback(() => {
        const {scrollY} = window;

        if (refMap.current) {
            const mapElem = refMap.current;

            if (window.innerWidth >= 1024) {
                const toMoveMap = calcToMove(mapElem, 0.1, scrollY);
                mapElem.style.transform = `translateY(${toMoveMap}px)`
            } else {
                mapElem.style.transform = 'none';
            }
        }

        if (refLogo.current) {
            const logoElem = refLogo.current;
            const toMoveLogo = calcToMove(logoElem, 0.3, scrollY);

            if (window.innerWidth >= 1024) {
                logoElem.style.transform = `translateX(-50%) translateX(${toMoveLogo}px)`
            } else {
                logoElem.style.transform = `translateX(-50%) translateY(${toMoveLogo * -1}px)`
            }
        }
    }, []);

    useEffect(() => {
        window?.addEventListener("scroll", handleScroll);
        handleScroll();

        return () => window?.removeEventListener("scroll", handleScroll);
    }, [handleScroll]);

    const locationData = getLocationData(location);
    const lang = locationsLang[location];

    if (!lang) {
        throw new Error(`Location "${location}" not found`);
    }

    return (
        <section className="bg-black text-white relative py-16 sm:pb-0 md:pb-16 lg:py-24">
            <Container className="flex flex-col lg:flex-row lg:items-center gap-20 lg:gap-10">
                <div className="absolute top-0 left-0 w-screen h-full overflow-hidden">
                    <div ref={refLogo}
                         className="absolute top-0 left-1/2 -translate-x-1/2 pointer-events-none ease-out transition-transform duration-500"
                    >
                        <LocationBgComponent location={location} height="500"/>
                    </div>
                </div>

                <div className="flex-1 relative flex flex-col justify-center gap-4">
                    <header>
                        <Title className="flex flex-col sm:flex-row sm:items-center gap-1">
                            {locationData.address.street}
                        </Title>
                    </header>

                    <Typo className="text-gray-200 max-w-lg">
                        <span dangerouslySetInnerHTML={{__html: lang.body}}/>
                    </Typo>

                    <div className="font-semibold">
                        <div className="flex flex-col items-start gap-6">
                            <Typo className="max-w-[55ch]" dangerouslySetInnerHTML={{__html: lang.meta.mapBody}}/>
                            <Typo>
                                {locationData.address.street}<br/>
                                {locationData.address.city}<br/>
                            </Typo>
                            {locationData.status === 'opened' && (
                                <div className="mt-4">
                                    <Button label={locationsLang.common.ctaMore}
                                            theme="secondary"
                                            href={`/locations/${location}`}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <div className="flex-1 relative -mb-48 sm:-mb-32 md:-mb-28 lg:-mb-48">
                    <div className="ease-out transition-transform duration-1000" ref={refMap}>
                        <Map location={location}
                             className="max-w-[500px] md:max-w-2xl rounded-lg overflow-hidden"
                        />
                    </div>
                </div>
            </Container>
        </section>
    )
}
