import {useEffect} from "react";
import SvgCross from "../svg/SvgCross";
import {useRouter} from "next/router";
import localized from "../locale/locales";
import LightboxBackground from "./LightboxBackground";

export default function Lightbox({children, active, onClose, size = 'big', className}) {
    const {locale} = useRouter();
    const lang = localized[locale];

    useEffect(() => {
        const handleKeyPress = (e) => {
            if (active && e.keyCode === 27) {
                onClose();
            }
        };

        window?.addEventListener('keyup', handleKeyPress);
        return () => window?.removeEventListener('keyup', handleKeyPress);
    });

    const bgClasses = active ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none';
    const wrapperClasses = !active ? 'translate-y-24' : '';

    let sizeClasses = '';

    switch (size) {
        case 'small':
            sizeClasses = 'max-w-md md:max-w-xl';
            break;
        case 'medium':
            sizeClasses = 'max-w-md md:max-w-3xl';
            break;
        default:
            sizeClasses = 'max-w-lg md:max-w-5xl';
            break;
    }

    return (
        <LightboxBackground onClick={onClose} active={active}>
            <aside
                className={`relative px-6 md:px-12 pt-8 md:pt-12 pb-2 md:pb-6 w-full my-auto rounded-lg bg-white text-black shadow-lg transition-transform duration-300 ${sizeClasses} ${wrapperClasses}`}
                onClick={e => e.stopPropagation()}
            >
                <button
                    className="absolute top-3 md:top-5 right-3 md:right-5 p-2 bg-gray-200 mouse-hover:bg-gray-300 transition-colors leading-none rounded-md text-0"
                    onClick={onClose}>
                    <SvgCross width={16}
                              height={16}
                              id="lightbox"
                    /> {lang.common.close}
                </button>
                {children}
            </aside>
        </LightboxBackground>
    )
}
