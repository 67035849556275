export default function SvgCalendar(props) {
    return (
        <svg width="26" height="27" viewBox="0 0 26 27" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path d="M20.219 27H0V3.30798H25.9707V6.13911H2.78781V24.1689H20.219V27Z"
                  fill="currentColor"/>
            <path d="M8.39279 0H5.60498V7.77815H8.39279V0Z" fill="currentColor"/>
            <path d="M20.4243 0H17.6365V7.77815H20.4243V0Z" fill="currentColor"/>
            <path opacity="0.6" d="M26 3.39734H23.2122V25.5397H26V3.39734Z"
                  fill="url(#paint0_linear_963:547a)"/>
            <path
                d="M25.912 25.0033C25.6479 24.2881 24.8262 23.9305 24.122 24.1987C23.4177 24.4669 23.0655 25.3013 23.3296 26.0166C23.5937 26.7318 24.4154 27.0894 25.1197 26.8212C25.824 26.553 26.1761 25.7483 25.912 25.0033Z"
                fill="currentColor"/>
            <defs>
                <linearGradient id="paint0_linear_963:547a" x1="31.044" y1="8.25629" x2="18.646"
                                y2="21.1273" gradientUnits="userSpaceOnUse">
                    <stop stopColor="currentColor" stopOpacity="0"/>
                    <stop offset="1" stopColor="currentColor"/>
                </linearGradient>
            </defs>
        </svg>
    )
}