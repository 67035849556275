import {useState} from "react";
import SectionMap from "./SectionMap";
import MapLocationTabButton from "../MapLocationTabButton";
import {getContactableLocations, getLocationAddress} from "../../helpers/locations";

export default function SectionMapLocationSwitcher() {
    const locationsItems = getContactableLocations();
    const [activeLocation, setActiveLocation] = useState(locationsItems[0]);

    return (
        <section>
            <nav className="w-full flex justify-center gap-2 xs:gap-4 sm:gap-6 -mb-px">
                {locationsItems.map((location) => {
                    const locationAddress = getLocationAddress(location);
                    return (
                        <MapLocationTabButton
                            location={location}
                            district={locationAddress.district}
                            key={`MapLocationTabButton: ${location}`}
                            active={activeLocation === location}
                            onClick={() => setActiveLocation(location)}
                        />
                    )
                })}
            </nav>

            <SectionMap location={activeLocation}/>
        </section>
    )
}
