export default function SvgPrice(props) {
    return (
        <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path opacity="0.6" d="M12.9977 25.697L11.1289 23.8517L24.4615 10.5762L26.3582 12.4216L12.9977 25.697Z"
                  fill="url(#paint0_linear_2899_1018)"/>
            <path
                d="M8.6188 23.411L0 14.9004L15.0899 0H27V11.7606L25.8843 12.8898L24.0155 11.0169L24.3502 10.6864V2.61653H16.2056L3.7376 14.9004L10.4876 21.5657L8.6188 23.411Z"
                fill="currentColor"/>
            <path
                d="M11.6309 23.6589C11.0173 23.9068 10.6826 24.5954 10.9336 25.2013C11.1846 25.8072 11.882 26.1377 12.4956 25.8899C13.1092 25.642 13.4439 24.9534 13.1929 24.3475C12.9698 23.7416 12.2446 23.411 11.6309 23.6589Z"
                fill="currentColor"/>
            <defs>
                <linearGradient id="paint0_linear_2899_1018" x1="25.5" y1="11.5662" x2="12.1413" y2="24.886"
                                gradientUnits="userSpaceOnUse">
                    <stop stopColor="currentColor"/>
                    <stop offset="1" stopColor="currentColor" stopOpacity="0"/>
                </linearGradient>
            </defs>
        </svg>
    )
}