import SvgPlay from "../svg/SvgPlay";
import {useRouter} from "next/router";
import localized from "../locale/locales";

export default function VideoPlay({showLabel = false, onPlay}) {
    const {locale} = useRouter();
    const lang = localized[locale];

    return (
        <button
            className="absolute top-2/4 left-2/4 bg-white -translate-y-2/4 -translate-x-2/4 rounded-6 inline-flex flex-col items-center justify-center h-22 w-22 transform scale-1 group-hover:scale-90 transition-all ease-in-out duration-500"
            onClick={onPlay}
        >
            <div className="translate-x-0.5">
                <SvgPlay className="h-9"/>
            </div>
            {showLabel && (
                <span className="text-3xs uppercase mt-3 leading-none font-medium">{lang.common.playVideo}</span>
            )}
        </button>
    )
}
