import { useEffect, useMemo, useState } from "react";
import useScrollTo from "react-spring-scroll-to-hook";
import { config } from "@react-spring/web";
import Container from "../components/Container";
import MainLayout from "../components/layouts/MainLayout";
import Panel from "../components/Panel";
import PanelLink from "../components/PanelLink";
import ScheduleTrial from "../components/ScheduleTrial";
import SectionHomepageHeader from "../components/sections/SectionHomepageHeader";
import SectionModern from "../components/sections/SectionModern";
import SectionPotential from "../components/sections/SectionPotential";
import SectionRates from "../components/sections/SectionRates";
import SvgClock from "../svg/SvgClock";
import { getCookiePreferences } from "../helpers/cookies";
import localized from "../locale/locales";
import { useRouter } from "next/router";
import SvgPrice from "../svg/SvgPrice";
import SvgCalendar from "../svg/SvgCalendar";
import SectionMapLocationSwitcher from "../components/sections/SectionMapLocationSwitcher";
import SectionContactForm from "../components/sections/SectionContactForm";
import { getInitialTrialSlots } from "../helpers/api/trial-slots";
import { getStudioData } from "../helpers/api/studio";
import PopupOpeningHours from "../components/popups/PopupOpeningHours";
import PopupBookClass from "../components/popups/PopupBookClass";
import PopupVideo from "../components/popups/PopupVideo";
import GalleryCommon from "../components/GalleryCommon";
import { getOpeningHours } from "../helpers/datetime";
import SectionConceptsCarousel from "../components/sections/SectionConceptsCarousel";

export default function Home({
  studioResponse,
  trialSlotsResponse,
  hasCookiePreferences,
  cookiePreferences,
}) {
  const { locale } = useRouter();
  const lang = localized[locale];

  const { scrollTo } = useScrollTo(config.default);

  const [showPopupOpeningHours, setShowPopupOpeningHours] = useState(false);
  const [showPopupBookClass, setShowPopupBookClass] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [activeVideo, setActiveVideo] = useState(null);

  const [hiddenNav, setHiddenNav] = useState(false);

  // TODO: Find a real solution.
  //  When switching languages, context from getServerSideProps is lost for some reason.
  const [studio, setStudio] = useState(studioResponse);

  const openingHours = useMemo(
    () => getOpeningHours(studio, lang),
    [studio, lang],
  );

  useEffect(() => {
    setHiddenNav(showPopupOpeningHours || showPopupBookClass);
  }, [showPopupOpeningHours, showPopupBookClass]);

  return (
    <MainLayout
      hasCookiePreferences={hasCookiePreferences}
      cookiePreferences={cookiePreferences}
      hiddenNav={hiddenNav}
      footerTheme="dark"
      title={lang.homepage.og.title}
      description={lang.homepage.og.description}
    >
      {/* TODO: merge all lightboxes */}
      <PopupOpeningHours
        openingHours={openingHours}
        active={showPopupOpeningHours}
        onClose={() => setShowPopupOpeningHours(false)}
      />

      <PopupBookClass
        active={showPopupBookClass}
        onClose={() => setShowPopupBookClass(false)}
      />

      <PopupVideo
        active={showPopup}
        onClose={() => {
          setShowPopup(false);
          setActiveVideo(null);
          setHiddenNav(false);
        }}
        activeVideo={activeVideo}
      />

      <SectionHomepageHeader />

      <Container>
        <Panel>
          <PanelLink
            onClick={() => setShowPopupBookClass(true)}
            className="panel-divider"
          >
            <SvgCalendar />
            <p>{lang.homepage.panel[0]}</p>
          </PanelLink>

          <PanelLink
            onClick={() => scrollTo("#memberships", -100)}
            className="panel-divider"
          >
            <SvgPrice />
            <p>{lang.homepage.panel[1]}</p>
          </PanelLink>

          <PanelLink onClick={() => setShowPopupOpeningHours(true)}>
            <SvgClock />
            <p>{lang.homepage.panel[2]}</p>
          </PanelLink>
        </Panel>
      </Container>

      <SectionModern
        setActiveVideo={setActiveVideo}
        openPopup={() => {
          setShowPopup(true);
          setHiddenNav(true);
        }}
      />

      <div className="w-screen overflow-hidden">
        <SectionConceptsCarousel
          // title={lang.homepage.concepts.title}
          subtitle={lang.homepage.concepts.subtitle}
        />
      </div>

      <div className="pt-16">
        <SectionMapLocationSwitcher />
      </div>

      <SectionPotential />

      <div
        style={{
          background:
            "linear-gradient(to bottom,rgba(255,255,255,1) 0%,rgba(255,255,255,1) 50%,rgba(0,0,0,1) 50%,rgba(0,0,0,1) 100%)",
        }}
      >
        <GalleryCommon />
      </div>

      <SectionRates />

      <ScheduleTrial
        initialSlots={trialSlotsResponse}
        setHiddenNav={setHiddenNav}
        className="pb-32"
      />

      <SectionContactForm />
    </MainLayout>
  );
}

export async function getServerSideProps({ req, res }) {
  const cookiePreferences = getCookiePreferences(req);

  const studio = await getStudioData();

  const trialSlots = await getInitialTrialSlots();

  return {
    props: {
      studioResponse: studio,
      trialSlotsResponse: trialSlots,
      ...cookiePreferences,
    },
  };
}
