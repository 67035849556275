import Lightbox from "../Lightbox";
import Button from "../Button";
import {useRouter} from "next/router";
import localized from "../../locale/locales";
import {getBookableLocations, locationData} from "../../helpers/locations";


export default function PopupBookClass({active, onClose}) {
    const {locale} = useRouter();
    const {common: lang} = localized[locale];

    const bookLinks = getBookableLocations()
        .map((location) => {
            return {
                href: locationData[location].bookClassLink,
                label: locationData[location].address.district
            }
        });

    return (
        <Lightbox active={active} onClose={onClose} size="small">
            <div className="mt-6 flex flex-col items-center gap-6 font-semibold pb-6 md:pb-4 max-w-max mx-auto">
                {bookLinks.map((link, key) => {
                    return (
                        <Button
                            key={`PopupBookClassLink: ${key}`}
                            target="_blank"
                            rel="nofollow noreferrer"
                            className="w-full"
                            {...link}
                        />
                    )
                })}
            </div>
        </Lightbox>
    )
}
