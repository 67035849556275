import TextWithImage from "../TextWithImage";
import modernImage from "../../public/img/modern.jpg";
import Title from "../Title";
import Typo from "../Typo";
import Button from "../Button";
import { useRouter } from "next/router";
import localized from "../../locale/locales";

export default function SectionModern({ setActiveVideo, openPopup }) {
  const { locale } = useRouter();
  const lang = localized[locale].homepage.modern;

  return (
    <div className="md:pb-16">
      <TextWithImage
        image={modernImage}
        imageAlt={lang.imageAlt}
        imageRatio="aspect-[63/46]"
        onPlay={() => {
          setActiveVideo("/videos/unleash_captions.mp4");
          openPopup();
        }}
      >
        <header className="mb-10 max-w-md">
          <Title>{lang.title}</Title>
        </header>

        <div className="flex flex-col gap-6 max-w-typo mb-12">
          {lang.body.map((item, key) => {
            return <Typo key={`Modern Body: ${key}`}>{item}</Typo>;
          })}
        </div>

        {/*<div className="text-center md:text-left">*/}
        <Button label={lang.cta} href="#free-trial" />
        {/*</div>*/}
      </TextWithImage>
    </div>
  );
}
