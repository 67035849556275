import Container from "../Container";
import Title from "../Title";
import Typo from "../Typo";
import {useRouter} from "next/router";
import localized from "../../locale/locales";

export default function SectionPotential() {
    const {locale} = useRouter();
    const lang = localized[locale].homepage.potential;

    return (
        <section>
            <Container className="pt-48 md:pt-32 xl:pt-40 pb-12">
                <header className="mb-8 max-w-xl">
                    <Title>{lang.title}</Title>
                </header>
                <Typo className="max-w-1.5xl">
                    <span dangerouslySetInnerHTML={{__html: lang.body}}/>
                </Typo>
            </Container>
        </section>
    )
}
