export default function SvgClock(props) {
    return (
        <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M18.5084 18.4295L11.9167 14.8724V5.96576H14.6513V13.3039L19.8325 16.1048L18.5084 18.4295Z"
                fill="currentColor"/>
            <path opacity="0.6"
                  d="M12.8668 26.6639C8.03095 26.4118 3.65568 23.639 1.46805 19.4377C-0.172676 16.2728 -0.460523 12.6877 0.662079 9.32675C1.78468 5.96575 4.20259 3.22094 7.45526 1.62447C9.26869 0.728203 11.226 0.252062 13.2698 0.196045L13.3273 2.85683C11.7154 2.88484 10.1322 3.27696 8.693 4.00517C6.10238 5.29356 4.17381 7.4782 3.28148 10.167C2.38916 12.8278 2.59065 15.7126 3.91474 18.2334C5.67061 21.5944 9.15355 23.807 13.0107 24.0031L12.8668 26.6639Z"
                  fill="url(#paint0_linear_963:547b)"/>
            <path
                d="M14.9681 2.29669C15.4574 1.3444 14.8529 0.196064 13.7591 0.028014C13.0683 -0.0840192 12.3775 0.280089 12.0608 0.868263C11.5715 1.82055 12.176 2.96889 13.2698 3.13694C13.9606 3.24897 14.6514 2.88486 14.9681 2.29669Z"
                fill="currentColor"/>
            <path opacity="0.6"
                  d="M13.7302 26.7199L13.6726 24.0591C15.2845 24.0311 16.8677 23.639 18.3069 22.9108C20.8976 21.6224 22.8261 19.4378 23.7184 16.749C24.6108 14.0602 24.3805 11.2033 23.0852 8.68257C21.3293 5.32158 17.8464 3.10892 13.9892 2.91286L14.1332 0.252075C18.969 0.50415 23.3442 3.27697 25.5319 7.47822C27.1726 10.6432 27.4605 14.2282 26.3379 17.5892C25.2153 20.9502 22.7973 23.695 19.5447 25.2915C17.7312 26.1878 15.7739 26.6639 13.7302 26.7199Z"
                  fill="url(#paint1_linear_963:547c)"/>
            <path
                d="M12.0896 24.7033C11.6003 25.6556 12.2048 26.8039 13.2986 26.972C13.9894 27.084 14.6803 26.7199 14.9969 26.1317C15.4862 25.1795 14.8818 24.0311 13.7879 23.8631C13.0971 23.779 12.3775 24.1151 12.0896 24.7033Z"
                fill="currentColor"/>
            <defs>
                <linearGradient id="paint0_linear_963:547b" x1="3.99458" y1="3.33895" x2="14.9967"
                                y2="25.6207" gradientUnits="userSpaceOnUse">
                    <stop stopColor="currentColor"/>
                    <stop offset="1" stopColor="currentColor" stopOpacity="0"/>
                </linearGradient>
                <linearGradient id="paint1_linear_963:547c" x1="22.9965" y1="23.5747" x2="11.9944"
                                y2="1.29309" gradientUnits="userSpaceOnUse">
                    <stop stopColor="currentColor"/>
                    <stop offset="1" stopColor="currentColor" stopOpacity="0"/>
                </linearGradient>
            </defs>
        </svg>
    )
}