import imageGallery01 from "../public/img/gallery/01.jpg";
import imageGallery02 from "../public/img/gallery/02.jpg";
import imageGallery03 from "../public/img/gallery/03.jpg";
import imageGallery04 from "../public/img/gallery/04.jpg";
import imageGallery05 from "../public/img/gallery/05.jpg";
import imageGallery06 from "../public/img/gallery/06.jpg";
import imageGallery07 from "../public/img/gallery/07.jpg";
import imageGallery08 from "../public/img/gallery/08.jpg";
import imageGallery09 from "../public/img/gallery/09.jpg";
import imageGallery10 from "../public/img/gallery/10.jpg";
import imageGallery11 from "../public/img/gallery/11.jpg";
import imageGallery12 from "../public/img/gallery/12.jpg";
import imageGallery13 from "../public/img/gallery/13.jpg";
import Container from "./Container";
import Gallery from "./Gallery";

export default function GalleryCommon() {
  return (
    <Container fullMobile={true}>
      <Gallery
        images={[
          imageGallery01,
          imageGallery02,
          imageGallery03,
          imageGallery04,
          imageGallery05,
          imageGallery06,
          imageGallery07,
          imageGallery08,
          imageGallery09,
          imageGallery10,
          imageGallery11,
          imageGallery12,
          imageGallery13,
        ]}
      />
    </Container>
  );
}
