import {useEffect, useRef} from "react";

export default function Video({url, active}) {
    const videoRef = useRef();

    useEffect(() => {
        if (videoRef) {
            active ? videoRef.current.play() : videoRef.current.pause();
        }
    }, [active]);

    return (
        <div className="pb-4 pt-6">
            <video src={url} controls autoPlay={true} ref={videoRef} className="w-full"/>
        </div>
    )
}
