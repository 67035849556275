import Title from "./Title";
import Typo from "./Typo";

export default function Banner({
                                   title,
                                   body,
                                   className = '',
                                   titleClassName = 'max-w-[21ch]',
                                   bodyClassName = 'max-w-[70ch]'
                               }) {
    return (
        <div className={`mx-auto flex flex-col gap-8 items-center ${className}`}>
            {title && (
                <Title className={titleClassName}>{title}</Title>
            )}

            {body && (
                <Typo dangerouslySetInnerHTML={{__html: body}} className={bodyClassName}/>
            )}
        </div>
    )
}
